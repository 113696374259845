<template>
  <div v-if="currentRow">

      <el-row class="mt-4">
        <el-col :span="11">
          <h6>DACY Protokoll</h6>
          <h6 class="text-warning" v-if="currentRow.meta.protocol">{{currentRow.meta.protocol.title}}</h6>

          <div v-if="currentRow.meta.protocol && currentRow.meta.protocol.kz_title"><h6>Protokollname lokal</h6>{{currentRow.meta.protocol.kz_title}}</div>
          <div v-if="currentRow.meta.protocol && currentRow.meta.protocol.drugs" class="mb-3 mt-3">
            <h6>Fachinformationen</h6>
            <div v-for="drug in currentRow.meta.protocol.drugs" :key="drug.id">
              <span v-if="drug.pdf_link"><a href="javascript:void(0)" @click="openBrowser(drug.pdf_link)">{{drug.title}}</a></span>
              <span v-else>{{drug.title}}</span>
            </div>
          </div>
          <div v-if="currentRow.meta.protocol && currentRow.meta.protocol.description"><h6>Beschreibung</h6>{{currentRow.meta.protocol.description}}</div>
          <div v-if="currentRow.meta.protocol && currentRow.meta.protocol.special"><h6>Besonderheiten</h6>{{currentRow.meta.protocol.special}}</div>
          <div v-if="currentRow.meta.protocol && currentRow.meta.protocol.oral"><h6>Oral</h6>{{currentRow.meta.protocol.oral}}</div>

        </el-col>
        <el-col :span="12" :offset="1">
          <h6>Ihre Bezeichnung</h6>
          <el-input
            placeholder="Bezeichnung"
            v-model="currentRow.title"></el-input>
          <h6 class="mt-4">Ihr Kürzel</h6>
          <el-input
            placeholder="Kürzel"
            v-model="currentRow.kz_title"></el-input>
          <h6 class="mt-4">Ihre ID</h6>
          <el-input
            type="number"
            placeholder="ID"
            v-model="currentRow.local_id"></el-input>
        </el-col>

      </el-row>
      <el-row class="mt-4">
          <el-col :span="10" class="text-left">
            <el-popconfirm
              v-if="currentRow.id"
              title="Sie möchten dieses Protokoll aus der Hausliste löschen?"
              confirmButtonText='Ja, löschen'
              confirmButtonType="danger"
              cancelButtonText='Nein'
              iconColor="red"
              @confirm="onRowDeleteConfirmed(currentRow)">
              <template #reference>
                <el-button type="danger">Löschen</el-button>
              </template>
            </el-popconfirm>
          </el-col>
          <el-col :span="14" class="dialog-footer text-right">
            <el-button @click="onAfterSave">Abbrechen</el-button>
            <el-button type="primary" @click="saveRow()">{{(currentRow.id ? ' Speichern' : 'Hinzufügen')}}</el-button>
          </el-col>
        </el-row>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SettingTherapyProtocolOwnListForm",
  props: ['protocolown'],
  components: {},
  data() {
    return {
      currentRow: null
    }
  },
  mounted: async function () {
    this.init()
  },
  computed: {
    ...mapGetters('protocolown', ['protocolown']),
  },
  watch: {
    'protocolown': async function() {
      this.init()
    }
  },
  methods: {
    ...mapActions('protocolown', ['deleteProtocolOwn', 'saveProtocolOwn']),
    async init() {
      this.currentRow = this.protocolown
    },
    async onRowDeleteConfirmed(row) {
      await this.deleteProtocolOwn(row)
      this.onAfterSave()
    },
    async saveRow() {
      await this.saveProtocolOwn(this.currentRow)
      this.onAfterSave()
    },
    onAfterSave() {
      this.$emit('after-save')
    }
  }
}
</script>
<style scoped>
</style>
